body {
    font-family: 'Noto Sans JP', sans-serif;
    color: grey;
    height: 100%;
}

.panels-container {
    margin: 3rem;
}

.panel {
    border-radius: 12px;
    padding: 1rem;
    box-shadow: whitesmoke 0px 4px 16px, whitesmoke 0px 8px 32px;
}

.issue-selection-panel {
    width: 35%;
    float: left;
}

.issue-view-panel {
    width: 60%;
    float: right;
    overflow-y: scroll;
    height: 800px;
}

.pagination {
    text-align: center;
}

.pagination button {
    width: 3rem;
    height: 3rem;
    border-radius: 12px;
    background-color: white;
    border: none;
    margin: 1rem;
    box-shadow: whitesmoke 0px 4px 16px, whitesmoke 0px 8px 32px;
    cursor: pointer;
    color: grey;
}

.selected {
    background-color: #F5F6F8 !important;
    color: rgb(47, 47, 47) !important;
    font-weight: bold;
}

.selection {
    padding: 1rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    border-radius: 12px;
    height: 60px;
}

.selection:hover {
    background-color: #F5F6F8;
    cursor: pointer;
}

.search {
    border: none;
    height: 2rem;
    width: 95%;
    margin-bottom: 2rem;
    font-size: 20px;
    color: grey;
    text-align: left;
    font-weight: bold;
}

.issue-view-panel .title {
    text-align: center;
    color: black;
    margin: 1rem;
}

.issue-view-panel .created-by {
    text-align: center;
}

.issue-view-panel .body {
    margin-top: 4rem;
    text-align: justify;
    font-weight: normal;
}

.comment {
    border: 2px solid whitesmoke;
    border-radius: 12px;
    padding: 2rem;
    margin: 2rem;
}

.open {
    color: green !important
}

.closed {
    color: red !important
}

.original-author {
    padding-top: 3rem;
    padding-bottom: 3rem;
    box-shadow: lightgrey 0px 4px 16px;
    margin-bottom: 5rem;
}

.user-profile {
    display: flex;
    cursor: pointer;
}

.username {
    margin-top: 0px;
    margin-left: 1rem;
    color: #0052A1;
}

.username h3,
h6 {
    margin: 0px;
}

.avatar {
    border-radius: 100%;
    height: 3rem;
}

.comment .created-at {
    color: grey;
}

.label-container {
    display: flex;
    margin: 0;
}

.all-labels-container {
    padding-left: 30px;
    display: flex;
}

.label-container h4 {
    margin-right: 20px;
}

.label-dot {
    background: rgb(66, 183, 42);
    border-radius: 100%;
    display: inline-block;
    height: 8px;
    width: 8px;
    margin: 7px;
    margin-top: 32px;
}

@media screen and (max-width: 1500px) {
    .issue-selection-panel {
        clear: both;
        width: 100%;
    }

    .issue-view-panel {
        float: both;
        width: 100%;
    }
}